const baseFitmentFields = ['Year', 'Make', 'Model', 'Engine'];
const extraFitmentFields = ['Submodel'];
const wheelsFields = ['wheel_diameter', 'wheel_width', 'wheel_bolt_pattern'];
const tiresFields = ['tire_width', 'tire_aspect', 'tire_rim'];
const ignoreFields = [...wheelsFields, ...tiresFields, 'Universal'];

const { customerGroupId = 0 } = globalThis.Convermax;

const isCategoryPage = globalThis.document.body.classList.contains('catalog-category-view');

let sortEntries = {
  'name': 'Name, A-Z',
  'name:desc': 'Name, Z-A',
  'price': 'Price, Low to High',
  'price:desc': 'Price, High to Low',
};

if (isCategoryPage) {
  sortEntries = {
    // eslint-disable-next-line camelcase
    category_position: 'Position',
    ...sortEntries,
  };
} else {
  sortEntries = {
    relevance: 'Best Match',
    ...sortEntries,
  };
}

const productAttrFields = ['Position', 'Notes', 'Qty', 'TransmissionMfrCode', 'TransmissionControlType'];

globalThis.Convermax.productAttrNameMap = (field) => {
  switch (field) {
    case 'Qty':
      return 'Per Car Qty';
    case 'TransmissionMfrCode':
      return 'Transmission Code';
    case 'TransmissionControlType':
      return 'Control Type';
    default:
      return field;
  }
};

globalThis.Convermax.cart = {
  formKey: () => globalThis.Cookies?.get('form_key'),
  minusButtonHandler: (productId) => {
    const result = document.getElementById(`qty_${productId}`);
    const resultBn = document.getElementById(`qty_bn_${productId}`);
    const qty = result.value;
    if (!isNaN(qty) && qty > 1) {
      result.value--;
      resultBn.value--;
    }
    return false;
  },
  plusButtonHandler: (productId) => {
    const result = document.getElementById(`qty_${productId}`);
    const resultBn = document.getElementById(`qty_bn_${productId}`);
    const qty = result.value;
    if (!isNaN(qty)) {
      result.value++;
      resultBn.value++;
    }
    return false;
  },
};

const blockSearchWrapper = document.querySelector('.block.block-search');
if (blockSearchWrapper) {
  blockSearchWrapper.className = '';
  blockSearchWrapper.innerHTML = `<div class="cm_tabs container">
      <div class="cm_tabs_header">
        <div class="cm_tab-title cm_tab-title cm_keyword-search active"><div>Search by</div> <div class="cm-tab-subtitle"><span>VIN</span>/<span>PART#</span>/<span>Keyword</span></div></div>
        <div class="cm_tab-title cm_tab-title cm_vehicle-search"><div>Search by</div> <div><span>VEHICLE</span></div></div>
        <div class="cm_tab-title cm_tab-title cm_wheel-search"><div>Search by</div> <div><span>WHEEL</span></div></div>
        <div class="cm_tab-title cm_tab-title cm_tire-search"><div>Search by</div> <div><span>TIRE</span></div></div>
        <div class="cm_tab-title cm_tab-title cm_barcode-scan"><div>Scan</div> <div><span>Barcode</span></div></div>
      </div>
      <div class="cm_tabs_content">
        <div class="cm_tab-content cm_keyword-search active">
          <p></p>
        </div>
        <div class="cm_tab-content cm_vehicle-search">
          <p></p>
        </div>
        <div class="cm_tab-content cm_wheel-search">
          <p></p>
        </div>
        <div class="cm_tab-content cm_tire-search">
          <p></p>
        </div>
      </div>
    </div>`;
  const tabScript = document.createElement('script');
  tabScript.innerHTML = `const onTabsClick = (e) => {
      const clickedTabTitle = e.target.closest('.cm_tab-title');
      if (clickedTabTitle) {
        if (clickedTabTitle.classList.contains('cm_barcode-scan')) {
          window.Convermax.StartBarcodeScanner((barcodeText) => {
            const searchBox = document.querySelector('.cm_keyword-search .cm_searchBox');
            searchBox.dispatchEvent(new CustomEvent('cm_set_query', { detail: barcodeText }));
          });
        } else if (!clickedTabTitle.classList.contains('active')) {
          const newActiveTab = [...clickedTabTitle.classList].find(cl => cl.endsWith('-search'));
          const currentActiveElems = [...e.currentTarget.querySelectorAll('.active')];
          const newActiveElems = [...e.currentTarget.querySelectorAll('.'+newActiveTab)];
          [...currentActiveElems, ...newActiveElems].forEach((elem) => elem.classList.toggle('active'));
        }
      }
    };
    document.querySelector('.cm_tabs').addEventListener('click', onTabsClick);`;
  blockSearchWrapper.appendChild(tabScript);
}

const anylineContainer = document.createElement('div');
anylineContainer.id = 'anyline_container';
const anylineCloseBtn = document.createElement('button');
anylineCloseBtn.className = 'anyline-close-button';
anylineCloseBtn.innerText = 'CLOSE';
anylineContainer.appendChild(anylineCloseBtn);
const anylineRoot = document.createElement('div');
anylineRoot.className = 'anyline-root';
anylineContainer.appendChild(anylineRoot);
document.body.appendChild(anylineContainer);

const anylineLicense =
  'ewogICJsaWNlbnNlS2V5VmVyc2lvbiIgOiAiMy4wIiwKICAiZGVidWdSZXBvcnRpbmciIDogIm9uIiwKICAibWFqb3JWZXJzaW9uIiA6ICIzNyIsCiAgIm1heERheXNOb3RSZXBvcnRlZCIgOiA2MCwKICAiYWR2YW5jZWRCYXJjb2RlIiA6IHRydWUsCiAgIm11bHRpQmFyY29kZSIgOiBmYWxzZSwKICAic3VwcG9ydGVkQmFyY29kZUZvcm1hdHMiIDogWyAiQUxMIiBdLAogICJwbGF0Zm9ybSIgOiBbICJKUyIgXSwKICAic2NvcGUiIDogWyAiQURWLiBCQVJDT0RFIiwgIkJBUkNPREUiLCAiT0NSIiwgIkFOWUxJTkVfT0NSIiBdLAogICJzaG93V2F0ZXJtYXJrIiA6IGZhbHNlLAogICJ0b2xlcmFuY2VEYXlzIiA6IDMwLAogICJ2YWxpZCIgOiAiMjAyNS0wMy0yOSIsCiAgImxpY2Vuc2VJZCIgOiAibHZ4Y0MwY1pwU0ZvOE5vTzl3S0ZyeVZqNm5pSklVZzZ0R0dmNlZ2Tk0iLAogICJqc0lkZW50aWZpZXIiIDogWyAidW1zc2VydmljZXMuY29tIiwgIlVNU1NlcnZpY2VzLmNvbSIsICJtY3N0YWdpbmcudW1zc2VydmljZXMuY29tIiBdCn0KCmM1UXRxTmpYbU8vTS9YWDR4b0hzVmJUKy9xU21tcFc1YitZUkpCYWVDZU42WEhxM0pyZU0rM3hnNTlvNDhFQzluUTAzRjBtL2ZxYzBUMzB6USszYVV4QW0xUHEraUxsaWFYZkVuL1JTOTZBVi9pc0h2SkFiWjlMdFVNQVhIajNSSncraDAwWVFBVzZIenlISXFlZXVLMG1MWFRxK0hray9jRklxK013bWYzcnlIUUQ1VnU1UlhwVzR3c1FMZ1FuNmVDVUh6RHBuU2t6TFArMzcxQ2poSTU4T2pncDhpSjlBUXVUeUJxZmdCSXNJU0Z5cmpSQ2hOTzhGMVNYQXRKbEE3SDltbEJSWjNUT2R1VEh1Ymc2UzFxM0pIclhxZWRxTlZVcGtid3EzYXM3OXlUOXo1M21hVU4rMk85SXh0amhLRkx2bU1xRVowaTF5bkhvcjNnWGdyZz09';

window.Convermax.StartBarcodeScanner = (resultCallback) => {
  startScanner('barcode', resultCallback);
};

window.Convermax.StartVinScanner = (resultCallback) => {
  startScanner('vin', resultCallback);
};

function startScanner(modePreset, resultCallback) {
  window.require(['https://js.anyline.com/release/42.4.1/anyline.js'], (anyline) => {
    anylineContainer.style.display = 'block';
    const Anyline = anyline.init({
      preset: modePreset,
      license: anylineLicense,
      element: anylineRoot,
      anylinePath: '/static/anylinejs',
      // debugAnyline: true,
    });

    const unmount = () => {
      try {
        Anyline.dispose();
      } finally {
        anylineContainer.style.display = 'none';
      }
    };

    Anyline.onResult = ({ result }) => {
      let sanitizedScanResult;
      if (Array.isArray(result?.barcodes)) {
        sanitizedScanResult = result.barcodes[0].value;
      } else if (Array.isArray(result)) {
        sanitizedScanResult = result.filter((r) => r?.identifier !== 'blobKey')[0].text;
      } else {
        sanitizedScanResult = result;
      }
      resultCallback(sanitizedScanResult);
      unmount();
    };

    anylineCloseBtn.onclick = unmount;

    Anyline.startScanning().catch((e) => {
      console.warn(e.message);
    });
  });
}

function InitFunc() {
  if (window.document.querySelector('#cm_results')) {
    window.document
      .querySelector('#cm_results')
      .classList.add('products', 'wrapper', 'list', 'products-list');
  }
}

function responseHandler(response) {
  globalThis.Convermax.VinParsedVehicle = response.Extra?.VinParsedVehicle;
  globalThis.Convermax.VinEntered = response.Extra?.VinEntered;
  return response;
}

function getCurrentCategoryId() {
  return document.querySelector('.breadcrumbs li.item:last-of-type')?.classList[1]?.replace('category', '');
}

function getLocalPreselection(pageType) {
  const currentCatId = getCurrentCategoryId();
  if (pageType === 'category' && currentCatId) {
    return [{ field: 'category_ids', term: currentCatId }];
  }
}

export default {
  platform: 'magento',
  searchPageUrl: '/convermax/result/',
  InitFunc,
  responseHandler,
  getLocalPreselection,
  SearchRequestDefaults: {
    pageSize: 36,
    extra:
      isCategoryPage || window.document.body.classList.contains('convermax-result-index')
        ? {
            customerGroupId,
          }
        : {
            customerGroupId,
            doNotHideWheelsTiresFacets: true,
          },
    sort: isCategoryPage ? 'category_position' : 'relevance',
  },
  autocomplete: {
    requestDefaults: {
      extra: {
        customerGroupId,
      },
    },
  },
  product: {
    searchResultsImageWidth: 250,
    searchResultsImageHeight: 250,
    fields: {
      id: 'id',
      title: 'name',
    },
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    isVehicleSelectionIsolated: true,
  },
  facets: {
    rangedFacet: [
      { fields: ['price'], name: 'priceFacet' },
      { fields: ['wheel_offset'], name: 'sliderFacet' },
      { fields: ['wheel_bore'], name: 'sliderFacet', step: '1/10//1', slider: { mode: 'LowerBound' } },
    ],
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: 'body.convermax-result-index #maincontent #cm_results',
      template: 'SearchPage',
    },
    {
      name: 'FilterChips',
      type: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'Facets',
      type: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: 'body.catalog-category-view #maincontent .column.main',
      template: 'SearchPage',
    },
    {
      name: 'VehicleSpecs',
      type: 'VehicleWidget',
      fields: extraFitmentFields,
    },
    {
      name: 'FacetBarWheels',
      type: 'FacetBar',
      fields: wheelsFields,
    },
    {
      name: 'FacetBarTires',
      type: 'FacetBar',
      fields: tiresFields,
    },
    { name: 'SearchResult' },
    {
      name: 'SearchHeader',
      sortEntries,
      sortSelectClass: 'select2-untouched',
    },
    {
      name: 'SearchBox',
      type: 'SearchBoxDialogButton',
      location: { selector: '.header .block-search', class: 'cm-search-box' },
      template: 'searchBox/dialogButtonAdaptable',
    },
    {
      name: 'HomeSearchBox',
      type: 'SearchBoxDialogButton',
      location: '.cm_tab-content.cm_keyword-search',
      template: 'searchBox/dialogButtonAdaptable',
    },
    {
      name: 'SearchBoxDialogButton',
      location: {
        selector: '#cm-mobile-search-box',
        class: 'cm-mobile-search-box',
      },
      template: 'searchBox/dialogButtonMobile',
    },
    {
      name: 'SearchBoxInTab',
      type: 'SearchBox',
    },
    {
      name: 'ProductDetailsAttrs',
      type: 'ProductData',
      location: {
        lastChildOf:
          'body.catalog-product-view .product.data.items #description, body.catalog-product-view .product.data.items #additional',
      },
      template: 'product/productData',
      fields: productAttrFields,
    },
    {
      name: 'HeaderVehicleWidget',
      type: 'VehicleWidget',
      location: '#cm-ymm',
      template: 'fitmentSearch/homeVehicleWidget',
    },
    {
      name: 'HomeVehicleWidget',
      type: 'VehicleWidget',
      location: '.cm_tab-content.cm_vehicle-search',
      template: 'fitmentSearch/homeVehicleWidget',
    },
    {
      name: 'HomeWheelBar',
      type: 'RequestPanel',
      location: '.cm_tab-content.cm_wheel-search',
      template: 'HomeWheelTireBar',
      needInitRequest: true,
      selectFields: wheelsFields,
    },
    {
      name: 'HomeTireBar',
      type: 'RequestPanel',
      location: '.cm_tab-content.cm_tire-search',
      template: 'HomeWheelTireBar',
      needInitRequest: true,
      selectFields: tiresFields,
    },
    {
      name: 'HeaderVehicleWidgetStub',
      type: 'VehicleWidget',
      location: '#cm-ymm-stub',
      template: 'fitmentSearch/homeVehicleWidget',
    },
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
      fields: baseFitmentFields,
      isAlwaysActive: true,
    },
    {
      name: 'HeaderVehicleWidget_tabs',
      type: 'VehicleWidget',
      isAlwaysActive: true,
    },
    {
      name: 'DialogVehicleWidget',
      type: 'VehicleWidget',
      fields: baseFitmentFields,
    },
    {
      name: 'VerifyFitment',
      type: 'VehicleWidget',
      location: {
        insertAfter: 'body.catalog-product-view .product-info-main .product-add-form',
      },
      template: 'fitmentSearch/verifyFitment',
    },
    {
      name: 'FitmentTable',
      location: {
        lastChildOf:
          'body.catalog-product-view .product.data.items #description, body.catalog-product-view .product.data.items #additional',
      },
      template: 'fitmentSearch/fitmentTable',
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialogVehicleSpecs',
      ignoreFields,
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'vehicle-dialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/dialog',
    },
  ],
};
