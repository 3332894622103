//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-852:_956,_604,_8868,_3104,_9932,_8928,_2047,_6792;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-852')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-852', "_956,_604,_8868,_3104,_9932,_8928,_2047,_6792");
        }
      }catch (ex) {
        console.error(ex);
      }